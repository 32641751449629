import React, { useState } from "react"
import classNames from "classnames"
import { makeStyles } from "@material-ui/styles"
import { Helmet } from "react-helmet"
import Paper from "../components/global/Paper"
import Layout from "../components/Layout/Layout"
import { TextField, Button } from "@material-ui/core"
import styles from "./contact.module.css"

const useStyles = makeStyles({
    root: {
        "& label.Mui-focused": {
            color: "#E8B2B2",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#E8B2B2",
        },
        "& fieldset": {
            borderColor: "#E8B2B2",
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "#E8B2B2",
            },
        },
    },
})

export default function Contact(props) {
    const [values, setValues] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
    })
    const handleChange = (name) => (event) => {
        setValues({ ...values, [name]: event.target.value })
    }
    const classes = useStyles()

    const input1Styles = classNames(styles.input1, classes.root)
    return (
        <Layout centered={false} style={{ backgroundColor: "#559CA660" }}>
            <Helmet>
                <title>Contact | Circa Wallcoverings</title>
                <meta name="Description" content="Get In Touch With Us -- " />
            </Helmet>
            <div className={styles.formContainer}>
                <Paper
                    width={"80%"}
                    margin="0 auto"
                    minHeight="60vh"
                    height="auto"
                    display="flex"
                    alignItems="center"
                    padding="25px"
                >
                    <form
                        className={styles.form}
                        name="contact"
                        method="POST"
                        data-netlify="true"
                        netlify-honeypot="required-for-bots"
                    >
                        <input
                            type="hidden"
                            name="form-name"
                            value="contact"
                        />
                        <div className={styles.contactText}>
                            <h2 className={styles.hook}>Drop us a line</h2>
                            <p className={styles.subHook}>
                                Let us know how we can help you make your space
                                more beautiful
                            </p>
                        </div>
                        <div>
                            <div className={styles.row}>
                                <TextField
                                    className={input1Styles}
                                    required
                                    id="outlined-name"
                                    label="Name"
                                    value={values.name}
                                    onChange={handleChange("name")}
                                    margin="normal"
                                    variant="outlined"
                                    inputProps={{
                                        type: "text",
                                        name: "name",
                                    }}
                                />
                                <TextField
                                    className={input1Styles}
                                    required
                                    id="outlined-email"
                                    label="Email"
                                    value={values.email}
                                    onChange={handleChange("email")}
                                    margin="normal"
                                    variant="outlined"
                                    inputProps={{
                                        type: "email",
                                        name: "email",
                                    }}
                                />
                            </div>
                            <div className={styles.row}>
                                <TextField
                                    className={classes.root}
                                    fullWidth
                                    id="outlined-subject"
                                    label="Subject"
                                    value={values.subject}
                                    onChange={handleChange("subject")}
                                    margin="normal"
                                    variant="outlined"
                                    inputProps={{
                                        type: "text",
                                        name: "subject",
                                    }}
                                />
                            </div>
                            <div className={styles.row}>
                                <TextField
                                    className={classes.root}
                                    required
                                    fullWidth
                                    multiline
                                    rows={5}
                                    id="outlined-message"
                                    label="Message"
                                    value={values.message}
                                    onChange={handleChange("message")}
                                    margin="normal"
                                    variant="outlined"
                                    inputProps={{
                                        type: "text",
                                        name: "message",
                                    }}
                                />
                                <input
                                    name="required-for-bots"
                                    style={{ display: "none" }}
                                />
                            </div>
                            <div className={styles.row2}>
                                <Button
                                    style={{
                                        backgroundColor: "#E8B2B2",
                                        borderColor: "#3C4858",
                                        borderWidth: "2px",
                                    }}
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                        <div className={styles.row2}>
                            <p>Having trouble using the form? Send us an email <a href="mailto:hello@circawallcovering.com">hello@circawallcovering.com</a></p>
                        </div>
                    </form>
                </Paper>
            </div>
        </Layout>
    )
}
